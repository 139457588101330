<template>
  <div id="parkingLotDetail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车场管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'parkingLotList' }"
        >停车场列表
      </el-breadcrumb-item>
      <el-breadcrumb-item>详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicsInfo">
      <h3>基础信息</h3>
      <ul class="info">
        <li>
          <span class="label">停车场名称：</span>
          <editable-input
            :value="info.parkingLotName"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_parkingLotName')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="parkingLotName"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">停车场地址：</span>
          <editable-input
            :value="info.parkingLotAddress"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-info_parkingLotAddress'
              )
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="parkingLotAddress"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">点位坐标：</span>
          <div class="custom-module">
            {{ info.parkingLotLng ? info.parkingLotLng : "---" }} ,
            {{ info.parkingLotLat ? info.parkingLotLat : "---" }}
            <i
              class="iconfont icon-bianji"
              title="编辑"
              @click="coordEdit()"
              v-if="buttonList.includes('parkingLotList-detail-info_coord')"
            ></i>
          </div>
        </li>
        <li>
          <span class="label">总停车位：</span>
          <editable-input
            :value="info.totalLots"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_totalLots')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="totalLots"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">剩余车位：</span>
          <editable-input
            :value="info.idleLots"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_idleLots')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="idleLots"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">产权车位数：</span>
          <editable-input
            :value="info.property"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_property')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="property"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">人防车位数：</span>
          <editable-input
            :value="info.civilDefense"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_civilDefense')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="civilDefense"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">停车场类型：</span>
          <editable-select
            :list="parkingLotType_list"
            :value="info.parkingLotType"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_parkingLotType')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="parkingLotType"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">商圈：</span>
          <editable-select
            :list="businessCircle_list"
            :value="info.businessCircleName"
            :isRead="
              info.parkingParentId
                ? true
                : !buttonList.includes(
                    'parkingLotList-detail-info_businessCircleId'
                  )
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="businessCircleId"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">主体类型：</span>
          <editable-select
            :list="select_subjectType"
            :value="info.subjectType"
            :isRead="
              info.parkingParentId
                ? true
                : !buttonList.includes('parkingLotList-detail-info_subjectType')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="subjectType"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">入口数：</span>
          <editable-input :value="info.entranceNum" />
        </li>
        <li>
          <span class="label">出口数：</span>
          <editable-input :value="info.exitusNum" />
        </li>
        <li>
          <span class="label">广告图片：</span>
          <div class="custom-module advertisingImg">
            <span v-if="!info.parkingLotPic">未设置</span>
            <el-image
              v-else
              :src="info.parkingLotPic"
              :preview-src-list="[info.parkingLotPic]"
              fit="contain"
            >
              >
            </el-image>
            <el-upload
              v-if="
                buttonList.includes('parkingLotList-detail-info_advertisingImg')
              "
              ref="upload"
              :headers="{ ['Authorization']: 'Bearer ' + token }"
              :action="BASE_URL + 'file/test'"
              :on-success="imgEditSuccess"
              :show-file-list="false"
            >
              <i class="iconfont icon-bianji" title="编辑"></i>
            </el-upload>
          </div>
        </li>
        <li>
          <span class="label">离场开闸：</span>
          <editable-select
            :list="select_whether"
            :value="info.autoOpen"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_autoOpen')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="autoOpen"
            @save="getInfo"
          />
        </li>
        <li v-if="info.parkingParentId">
          <span class="label">父级停车场：</span>
          <editable-select
            :value="info.parkingParentName"
            attr="parkingParentName"
          />
        </li>
        <li v-if="!info.parkingParentId">
          <span class="label">联合停车场：</span>
          <editable-select
            :list="parkingGroupList"
            :value="info.groupName"
            :isRead="
              info.parkingParentId
                ? true
                : !buttonList.includes(
                    'parkingLotList-detail-info_parkingGroupId'
                  )
            "
            :params="{ parkingLotId: parkingLotId }"
            option_key="parkingGroupId"
            option_label="groupName"
            url="/parking/edit"
            attr="parkingGroupId"
            @save="getInfo"
          />
        </li>
        <li v-if="!info.parkingParentId">
          <span class="label">开票税点：</span>
          <editable-select
            :list="select_taxRate"
            :value="info.taxRate"
            :isRead="!buttonList.includes('parkingLotList-detail-info_taxRate')"
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="taxRate"
            @save="getInfo"
          />
        </li>
        <li v-if="!info.parkingParentId">
          <span class="label">商户号：</span>
          <editable-input
            :value="info.shopId"
            :isRead="!buttonList.includes('parkingLotList-detail-info_shopId')"
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="shopId"
            @save="getInfo"
          />
        </li>
        <li v-if="!info.parkingParentId">
          <span class="label">门店号：</span>
          <editable-input
            :value="info.staffId"
            :isRead="!buttonList.includes('parkingLotList-detail-info_staffId')"
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="staffId"
            @save="getInfo"
          />
        </li>
        <li v-if="!info.parkingParentId">
          <span class="label">利率：</span>
          <editable-input
            :value="info.interestRate"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_interestRate')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="interestRate"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">优惠券核销：</span>
          <editable-select
            :list="select_whether"
            :value="info.couponFlag"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_couponFlag')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="couponFlag"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">挂单车核销：</span>
          <editable-select
            :list="select_whether"
            :value="info.freeCarFlag"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_freeCarFlag')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="freeCarFlag"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">忽略余位：</span>
          <editable-select
            :list="select_whether"
            :value="info.ignoreRemain"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_ignoreRemain')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="ignoreRemain"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">租赁车不占位：</span>
          <editable-select
            :list="select_whether"
            :value="info.ignoreLease"
            :isRead="
              !buttonList.includes('parkingLotList-detail-info_ignoreLease')
            "
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit"
            attr="ignoreLease"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">临时车可进时间：</span>
          <div class="custom-module advertisingImg">
            <i
              class="iconfont icon-bianji"
              title="编辑"
              @click="leaseOnlyChange"
            ></i>
          </div>
        </li>
        <li>
          <span class="label">第三方平台对接：</span>
          <editable-input
            :value="info.account"
            :isRead="!buttonList.includes('parkingLotList-detail-info_account')"
            :params="{ parkingLotId: parkingLotId }"
            url="/parking/edit/account"
            attr="account"
            @save="getInfo"
          />
        </li>
      </ul>
    </div>
    <div v-if="!info.parkingParentId" class="basicsInfo">
      <h3>
        <span>开票信息</span>
      </h3>
      <div class="invoice-operation">
        <el-button
          type="primary"
          @click="invoiceBind(true)"
          v-if="
            buttonList.includes('parkingLotList-detail-invoiceInfo_bind') &&
            !invoiceInfo
          "
          >开票绑定
        </el-button>
        <el-button
          type="primary"
          @click="invoiceBind(false)"
          v-if="
            buttonList.includes('parkingLotList-detail-invoiceInfo_unBind') &&
            invoiceInfo
          "
          >开票解绑
        </el-button>
      </div>

      <ul class="info" v-if="invoiceInfo">
        <li>
          <span class="label">税号：</span>
          <editable-input :value="invoiceInfo.taxNum" />
        </li>
        <li>
          <span class="label">开票员：</span>
          <editable-input :value="invoiceInfo.clerk" />
        </li>
        <li>
          <span class="label">销方地址：</span>
          <editable-input :value="invoiceInfo.salerAddress" />
        </li>
        <li>
          <span class="label">销方电话：</span>
          <editable-input :value="invoiceInfo.salerTel" />
        </li>
        <li>
          <span class="label">启用开票：</span>
          <editable-input :value="invoiceInfo.enable" />
        </li>
      </ul>
    </div>
    <div class="tab-table">
      <el-tabs v-model="tabsName">
        <el-tab-pane
          label="关联停车场"
          name="parkingLotList-detail-associatedParkingTab"
          v-if="tabList.includes('parkingLotList-detail-associatedParkingTab')"
        >
          <associated-parking />
        </el-tab-pane>
        <el-tab-pane
          label="出入口列表"
          name="parkingLotList-detail-passagewayTab"
          v-if="tabList.includes('parkingLotList-detail-passagewayTab')"
        >
          <passageway-list />
        </el-tab-pane>
        <el-tab-pane
          label="缴费记录"
          name="parkingLotList-detail-payRecordTab"
          v-if="tabList.includes('parkingLotList-detail-payRecordTab')"
        >
          <pay-record />
        </el-tab-pane>
        <el-tab-pane
          label="场内车辆"
          name="parkingLotList-detail-venueVehicleTab"
          v-if="tabList.includes('parkingLotList-detail-venueVehicleTab')"
        >
          <venue-vehicle />
        </el-tab-pane>
        <el-tab-pane
          label="收费标准"
          name="parkingLotList-detail-ratesStandardTab"
          v-if="tabList.includes('parkingLotList-detail-ratesStandardTab')"
        >
          <rates-standard />
        </el-tab-pane>
        <el-tab-pane
          label="会员收费"
          name="parkingLotList-detail-temporaryChargesTab"
          v-if="tabList.includes('parkingLotList-detail-temporaryChargesTab')"
        >
          <temporary-charges />
        </el-tab-pane>
        <el-tab-pane
          label="车辆列表"
          name="parkingLotList-detail-vehicleListTab"
          v-if="tabList.includes('parkingLotList-detail-vehicleListTab')"
        >
          <vehicle-list-tab />
        </el-tab-pane>
        <el-tab-pane
          label="商户管理"
          name="parkingLotList-detail-merchantTab"
          v-if="
            tabList.includes('parkingLotList-detail-merchantTab') &&
            !this.info.parkingParentId
          "
        >
          <merchant-tab />
        </el-tab-pane>
        <el-tab-pane
          label="车辆考勤"
          name="parkingLotList-detail-vehicleAttendanceTab"
          v-if="
            tabList.includes('parkingLotList-detail-vehicleAttendanceTab') &&
            !this.info.parkingParentId
          "
        >
          <vehicle-attendance-tab />
        </el-tab-pane>
        <el-tab-pane
          label="车位管理"
          name="parkingLotList-detail-parkingSpaceTab"
          v-if="tabList.includes('parkingLotList-detail-parkingSpaceTab')"
        >
          <parking-space-tab />
        </el-tab-pane>
      </el-tabs>
    </div>
    <edit-coord-dialog ref="editCoordDialog" :getInfo="getInfo" />
    <invoice-bind-dialog ref="invoiceBindDialog" @success="getInvoiceInfo" />
    <lease-only-dialog ref="leaseOnlyDialog" @success="getInfo" />
  </div>
</template>
<script>
export default {
  components: {
    associatedParking: () => import("./tabs/associatedParking.vue"),
    passagewayList: () => import("./tabs/passagewayList.vue"),
    payRecord: () => import("./tabs/payRecord.vue"),
    venueVehicle: () => import("./tabs/venueVehicle/venueVehicle.vue"),
    ratesStandard: () => import("./tabs/ratesStandard.vue"),
    temporaryCharges: () => import("./tabs/temporaryCharges.vue"),
    merchantTab: () => import("./tabs/merchant/merchant.vue"),
    vehicleAttendanceTab: () =>
      import("./tabs/vehicleAttendance/vehicleAttendance.vue"),
    parkingSpaceTab: () => import("./tabs/parkingSpaceTab/parkingSpaceTab.vue"),
    vehicleListTab: () => import("./tabs/vehicleListTab/vehicleListTab.vue"),
    editableInput: () => import("@/components/editable-input.vue"),
    editableSelect: () => import("@/components/editable-select.vue"),
    editCoordDialog: () => import("./editCoordDialog.vue"),
    invoiceBindDialog: () => import("./invoiceBindDialog.vue"),
    leaseOnlyDialog: () => import("./leaseOnlyDialog.vue"),
  },
  data() {
    return {
      tabsName: "parkingLotList-detail-associatedParkingTab",
      info: {},
      invoiceInfo: null,
      parkingLotId: "",
      parkingGroupList: [],
      parkingLotType_list: [
        {
          value: "地下停车场",
          label: "地下停车场",
        },
        {
          value: "地面停车场",
          label: "地面停车场",
        },
      ],
      businessCircle_list: [],
      BASE_URL: process.env.VUE_APP_BASE_URL,
    };
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
    tabList() {
      let list = this.$store.state.controlAuthority.tabList;
      let tabsName_list = [
        "parkingLotList-detail-associatedParkingTab",
        "parkingLotList-detail-passagewayTab",
        "parkingLotList-detail-payRecordTab",
        "parkingLotList-detail-venueVehicleTab",
        "parkingLotList-detail-ratesStandardTab",
        "parkingLotList-detail-temporaryChargesTab",
        "parkingLotList-detail-vehicleListTab",
        "parkingLotList-detail-merchantTab",
        "parkingLotList-detail-vehicleAttendanceTab",
        "parkingLotList-detail-parkingSpaceTab",
      ];
      if (!this.info.parkingParentId) {
        tabsName_list = [
          "parkingLotList-detail-associatedParkingTab",
          "parkingLotList-detail-passagewayTab",
          "parkingLotList-detail-payRecordTab",
          "parkingLotList-detail-venueVehicleTab",
          "parkingLotList-detail-ratesStandardTab",
          "parkingLotList-detail-temporaryChargesTab",
          "parkingLotList-detail-vehicleListTab",
          "parkingLotList-detail-parkingSpaceTab",
        ];
      }

      for (let item of tabsName_list) {
        if (list.includes(item)) {
          this.tabsName = item;
          break;
        }
      }
      return list;
    },
    parkingLotList() {
      return this.$store.state.select.parkingLotList;
    },
  },
  created() {
    this.parkingLotId = this.$route.params.id;
    this.getInfo();
    this.getInvoiceInfo();
    this.getParkingGroup();
  },
  methods: {
    leaseOnlyChange() {
      this.$refs.leaseOnlyDialog.allowTemp = this.info.allowTemp;
      this.$refs.leaseOnlyDialog.show = true;
    },
    async invoiceBind(isBind) {
      if (isBind) {
        this.$refs.invoiceBindDialog.show = true;
      } else {
        this.$confirm("此操作将解绑开票信息, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(async () => {
            let res = await this.$http.post(`/parking/invoice/bind`, {
              parkingLotId: this.parkingLotId,
            });
            if (res.code === 0) {
              this.$message.success("解绑成功");
              this.getInvoiceInfo();
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch(() => {});
      }
    },
    async getInvoiceInfo() {
      try {
        let res = await this.$http.get(
          `/invoice/parking/list/${this.parkingLotId}`
        );
        if (res.code === 0) {
          this.$set(this, "invoiceInfo", res.data);
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getParkingGroup() {
      try {
        let res = await this.$http.get("/parking/group/list/all");
        if (res.code === 0) {
          this.parkingGroupList = res.data;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async getBusinessList() {
      try {
        let res = await this.$http.get("/business/circle/list/all");
        if (res.code === 0) {
          this.businessCircle_list = res.data.map((item) => {
            if (this.info.businessCircleId === item.businessCircleId) {
              Object.assign(this.info, {
                businessCircleName: item.businessCircleName,
              });
            }
            return {
              value: item.businessCircleId,
              label: item.businessCircleName,
            };
          });
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
    async imgEditSuccess(res) {
      if (res.code === 0) {
        try {
          let editRes = await this.$http.post(`/parking/edit`, {
            parkingLotId: this.parkingLotId,
            parkingLotPic: res.data.picUrl,
          });
          if (editRes.code === 0) {
            this.$message.success("操作成功");
            this.getInfo();
          } else {
            this.$message.error(editRes.msg);
          }
        } catch (err) {
          this.$message.error(err);
        }
        this.getInfo();
      } else {
        this.$message.error(res.msg);
      }
    },

    coordEdit() {
      this.$refs.editCoordDialog.show = true;
      this.$refs.editCoordDialog.form = {
        address: this.info.parkingLotAddress,
        longitude: this.info.parkingLotLng,
        latitude: this.info.parkingLotLat,
      };
    },
    async getInfo() {
      try {
        let res = await this.$http.get(`/parking/detail/${this.parkingLotId}`);
        if (res.code === 0) {
          this.info = res.data;
          this.getBusinessList();
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
#parkingLotDetail {
  .invoice-operation {
    margin-bottom: 20px;
  }
  .advertisingImg {
    .el-image {
      height: 50px;
    }
  }

  .tab-table {
    padding: 15px;
  }
}
</style>
